import React, {FC, useState} from "react";
import {Company, Exam, ExamParticipant, Project} from "../api/dto";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Input} from "../components/form/Input";
import {useApiCall} from "../api/api";
import {useRefresh} from "../components/RefreshController";

export const RenameContractorModal: FC<{contractor: string, participants: ExamParticipant[], companyId: string, exams: Exam[]}> = props => {
  const {updateContractor} = useApiCall()
  const refresh = useRefresh()
  const [name, setName] = useState(props.contractor)
  const sameNameContractors = props.participants.filter(participant => participant.contractor === name && props.exams.some(exam => exam.id === participant.examId))
  const save = async () => {
    await updateContractor(props.companyId, name, props.contractor)
    refresh()
  }
  return <div>
    <Input type={'text'} label={'Naam'} value={name} onChange={setName} />
    {(sameNameContractors.length > 0 && name !== props.contractor) ? <div className={'bg-amber-100 mt-4 border-2 border-amber-200 text-amber-900 mb-4 text-sm rounded-lg px-4 py-3'}>
      <h2 className={"font-bold"}>Contractor samenvoegen</h2>
      Er zijn een andere examens met deze contractor binnen dit bedrijf, als je opslaat worden deze samengevoegd.
    </div> : <></>}
    <ModalFooter text={'Opslaan'} onSubmit={save}/>
  </div>
}
