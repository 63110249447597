
export class Assessor {
  constructor(
    public readonly id: string,
    public readonly first_name: string,
    public readonly last_name: string,
    public readonly options: any,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {
  }

  isEnabled(): boolean {
    return this.options?.enabled !== 'false'
  }

  static fromJson(json: any): Assessor {
    return new Assessor(
      json['id'],
      json['first_name'],
      json['last_name'],
      json['options'] ? Array.isArray(json['options']) ? {} : json['options'] : {},
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export type CategoryScoringMap = {
  [category: string]: {
    classification: string,
    color: string,
    fromScore: number
  }[]
}

export const AdminUserRole = 'admin'
export const AssessorUserRole = 'assessor'
export const LeadAssessorUserRole = 'lead-assessor'
export interface UserRole {
  id: string
  name: string
  containerId: string
  description: string
  composite: boolean
  clientRole: boolean
}
export class User {
  constructor(
    public readonly id: string,
    public readonly username: string,
    public readonly first_name: string,
    public readonly last_name: string,
    public readonly email: string,
    public readonly enabled: boolean,
    private readonly _roles: UserRole[],
    public readonly companies: string[],
    public readonly question_sets: string[],
  ) {
  }

  static fromJson(json: any): User {
    return new User(
      json['id'],
      json['username'],
      json['firstName'] ?? null,
      json['lastName'] ?? null,
      json['email'] ?? null,
      json['enabled'],
      json['roles'],
      json['companies'],
      json['question_sets'],
    )
  }

  get role(): UserRole|null {
    const adminRole = this.roles.find(role => role.name === AdminUserRole)
    const leadAssessorRole = this.roles.find(role => role.name === LeadAssessorUserRole)
    const assessorRole = this.roles.find(role => role.name === AssessorUserRole)
    return adminRole ?? leadAssessorRole ?? assessorRole ?? null
  }

  get roles(): UserRole[] {
    return this._roles.filter(role => [AdminUserRole, AssessorUserRole, LeadAssessorUserRole].includes(role.name))
  }
}

export class Company {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly logo: string|null,
    public readonly scoringOverride: CategoryScoringMap|null,
    public readonly isArchived: boolean,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {
  }

  static fromJson(json: any): Company {
    return new Company(
      json['id'],
      json['name'],
      json['logo'],
      json['override_category_scores']?.['scoring_per_score_category'] ?? null,
      json['is_archived'] ?? false,
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class QuestionSet {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly type: string,
    public readonly questions: any[],
    public readonly scoringPerCategory: CategoryScoringMap,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {
  }

  static fromJson(json: any): QuestionSet {
    return new QuestionSet(
      json['id'],
      json['name'],
      json['type'],
      json['questions'],
      json['default_category_scores']?.['scoring_per_score_category'] ?? {},
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class Project {
  constructor(
    public readonly id: string,
    public readonly companyId: string,
    public readonly name: string,
    public readonly logo: string|null,
    public readonly scoringOverride: CategoryScoringMap|null,
    public readonly isArchived: boolean,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {
  }

  static fromJson(json: any): Project {
    return new Project(
      json['id'],
      json['company_id'],
      json['name'],
      json['logo'],
      json['override_category_scores']?.['scoring_per_score_category'] ?? null,
      json['is_archived'] ?? false,
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class Exam {
  public readonly status: 'Concept'|'In uitvoering'|'Afgerond'|'Gearchiveerd'
  public readonly lastUpdated: Date
  constructor(
    public readonly id: string,
    public readonly projectId: string,
    public readonly companyId: string,
    public readonly questionSetId: string,
    public readonly assessorId: string|null,
    public readonly name: string|null,
    public readonly examAt: Date,
    public readonly startedAt: Date|null,
    public readonly finishedAt: Date|null,
    public readonly scoringOverride: CategoryScoringMap|null,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
    public readonly archivedAt: Date|null,
  ) {
    if (this.archivedAt !== null) {
      this.status = 'Gearchiveerd'
    } else if (this.finishedAt !== null) {
      this.status = 'Afgerond'
    } else if (this.startedAt === null) {
      this.status = 'Concept'
    } else {
      this.status = 'In uitvoering'
    }

    this.lastUpdated = new Date(Math.max(this.createdAt.getTime(), this.updatedAt.getTime(), startedAt?.getTime() ?? 0, finishedAt?.getTime() ?? 0))
  }


  static fromJson(json: any): Exam {
    return new Exam(
      json['id'],
      json['project_id'],
      json['company_id'],
      json['question_set_id'],
      json['assessor_id'] ?? null,
      json['name'] ?? null,
      new Date(json['exam_at']),
      json['started_at'] ? new Date(json['started_at']) : null,
      json['finished_at'] ? new Date(json['finished_at']) : null,
      json['override_category_scores']?.['scoring_per_score_category'] ?? null,
      new Date(json['created_at']),
      new Date(json['updated_at']),
      json['archived_at'] ? new Date(json['archived_at']) : null,
    )
  }
}

export class ExamParticipant {
  constructor(
    public readonly id: string,
    public readonly examId: string,
    public readonly contractor: string,
    public readonly subContractor: string,
    public readonly name: string,
    public readonly position: number,
    public readonly noShow: boolean,
    public readonly badgeNumber: string|null,
    public readonly nationality: string|null,
    public readonly certification: string|null,
    public readonly score: string|null,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {
  }

  static fromJson(json: any): ExamParticipant {
    return new ExamParticipant(
      json['id'],
      json['exam_id'],
      json['contractor'],
      json['subcontractor'],
      json['name'],
      json['position'],
      json['no_show'] ?? false,
      json['badge_number'] ?? null,
      json['nationality'] ?? null,
      json['certification'] ?? null,
      json['score_preview'] ?? null,
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class ProposedChange {
  constructor(
    public readonly action: string,
    public readonly idName: string,
    public readonly oldValue: string|null,
    public readonly newValue: string,
  ) {
  }

  static fromJson(json: any): ProposedChange {
    return new ProposedChange(
      json['action'],
      json['id_name'],
      json['old_value'],
      json['new_value'],
    )
  }
}

export class Role {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly description: string,
  ) {
  }

  static fromJson(json: any): Role {
    return new Role(
      json['id'],
      json['name'],
      json['description'],
    )
  }
}

export class RealmRole {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly description: string,
    public readonly composite: boolean,
    public readonly clientRole: boolean,
    public readonly containerId: string,
  ) {
  }

  static fromJson(json: any): RealmRole {
    return new RealmRole(
      json['id'],
      json['name'],
      json['description'],
      json['composite'],
      json['clientRole'],
      json['containerId'],
    )
  }
}
