import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {ContentContainer} from "../components/content/ContentContainer";
import {PageHeader} from "../components/content/PageHeader";
import {useApi, useFetchedResource} from "../api/APIContext";
import {Assessor, Company, Exam, ExamParticipant, Project, QuestionSet} from "../api/dto";
import {useNavigate, useParams} from "react-router-dom";
import {Loading} from "../components/Loading";
import {useApiCall} from "../api/api";
import {useRefresh, useRefreshEffect} from "../components/RefreshController";
import {SectionHeader} from "../components/content/SectionHeader";
import {DataTable} from "../components/data/DataTable";
import {SectionDivider} from "../components/content/SectionDivider";
import {Card} from "../components/Card";
import {
  faArchive, faArrowsLeftRight, faBackward,
  faCheckCircle, faCircleExclamation,
  faClock, faCopy,
  faDownload, faEllipsisV,
  faPencil, IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, IconButton} from "../components/form/Button";
import {Select} from "../components/form/Select";
import {useKeycloak} from "@react-keycloak/web";
import {useModal} from "../components/layout/ModalProvider";
import {CloneExamModal} from "../modals/CloneExamModal";
import {MoveExamParticipantModal} from "../modals/MoveExamParticipantModal";
import {EditExamModal} from "../modals/EditExamModal";

interface EnrichedExam extends Exam {
  projectName: string
  companyName: string
  questionSetName: string
  project: Project
  company: Company
  questionSet: QuestionSet
  participants: ExamParticipant[]
}
export const ExamDetailsPage: FC<{}> = () => {
  const {examId} = useParams()
  const {exams, projects, companies, questionSets, assessors, loading} = useApi()

  // Participants
  const {listExamParticipants} = useApiCall()
  const participants = useFetchedResource<ExamParticipant[], string>((exam: string) => listExamParticipants(exam))
  useRefreshEffect(() => {
    participants.reload(examId ?? '')
  }, [examId])

  const enrichedExam: EnrichedExam|null = useMemo(() => {
    const projectsMap = projects.reduce((map, project) => {
      map[project.id] = project
      return map
    }, {} as {[key: string]: Project})
    const companiesMap = companies.reduce((map, company) => {
      map[company.id] = company
      return map
    }, {} as {[key: string]: Company})
    const questionSetsMap = questionSets.reduce((map, questionSet) => {
      map[questionSet.id] = questionSet
      return map
    }, {} as {[key: string]: QuestionSet})
    const exam = exams.find(exam => exam.id === examId)
    if (!exam) return null
    const project = projectsMap[exam.projectId]
    const company = companiesMap[project?.companyId ?? '']
    const questionSet = questionSetsMap[exam.questionSetId]
    return {
      ...exam,
      projectName: project?.name ?? 'Onbekend project',
      project,
      companyName: company?.name ?? 'Onbekend bedrijf',
      company,
      questionSetName: questionSet?.name ?? 'Onbekende vragenlijst',
      questionSet,
      participants: participants.resource ?? []
    }
  }, [exams, projects, companies, questionSets, examId, participants])

  return <ContentContainer size={'md'}>
    <Loading loading={loading || !enrichedExam}>
      {enrichedExam && <div>
        <div className={"flex justify-between items-center"}>
          <PageHeader>Assessment {enrichedExam.name??''}</PageHeader>
          <div className={"h-12 mb-6 flex items-center"}>
            <ExamAssessor exam={enrichedExam} assessors={assessors} currentAssessor={enrichedExam.assessorId ? enrichedExam.assessorId : '-'} />
            <ExamDetailsContextMenu exam={enrichedExam} exams={exams} projects={projects} companies={companies} />
          </div>
        </div>
        <dl className={"flex items-center"}>
          <dt
            className={"rounded-l border-t border-l border-b border-brand-200 bg-brand-50 text-brand-600 px-2 font-medium"}>Project
          </dt>
          <dd className={"rounded-r border border-brand-200 px-2 mr-3"}>{enrichedExam.projectName}</dd>
          <dt
            className={"rounded-l border-t border-l border-b border-brand-200 bg-brand-50 text-brand-600 px-2 font-medium"}>Bedrijf
          </dt>
          <dd className={"rounded-r border border-brand-200 px-2 mr-3"}>{enrichedExam.companyName}</dd>
          <dt
            className={"rounded-l border-t border-l border-b border-brand-200 bg-brand-50 text-brand-600 px-2 font-medium"}>Vragenlijst
          </dt>
          <dd className={"rounded-r border border-brand-200 px-2 mr-3"}>{enrichedExam.questionSetName}</dd>
        </dl>
        <ExamResultsOverview exam={enrichedExam}/>
        <ExamParticipantsOverview exam={enrichedExam}/>
      </div>}
    </Loading>
  </ContentContainer>
}

const ExamAssessor: FC<{ exam: Exam, assessors: Assessor[], currentAssessor: string}> = props => {
  const {addOrUpdateExam} = useApiCall();
  const reload = useRefresh()
  const refactoredAssessors = props.assessors.map((item) => [item.id, item.first_name + " " + item.last_name])
  const [selectedAssessor, setSelectedAssessor] = useState<string|null>(props.currentAssessor)

  const saveNewValues = useCallback(async (assessorId: string) => {
    await addOrUpdateExam(
      props.exam.id,
      {
        assessor_id: assessorId,
      })
    reload()
  }, []);

  return <div>
    <Select label={'Assessor'} inline value={selectedAssessor ?? '-'} options={Object.fromEntries(refactoredAssessors)} onChange={async (key) => {
      setSelectedAssessor(key)
      await saveNewValues(key)
    }} />
  </div>
}

const ExamResultsOverview: FC<{ exam: EnrichedExam }> = props => {
  const {getExamExportUrl, getExamReportUrl} = useApiCall()
  const [override, setOverride] = useState(false)
  return <div>
    {/*<SectionDivider/>*/}
    {/*<SectionHeader>Resultaten</SectionHeader>*/}
    <Card title={'Resultaten'}>
      {(props.exam.status === 'Afgerond' || override) && <div className={'flex flex-col items-center space-y-3 py-4'}>
        <FontAwesomeIcon icon={faCheckCircle} className={'text-brand-900'}/>
        <h2 className={"font-lg font-semibold text-brand-800"}>Finished</h2>
        {props.exam.questionSet.type.toLowerCase() === 'fir' ? <div className={"w-full"}>
          <div className={"grid grid-cols-2 gap-4"}>
            <div className={""}>
              <h3 className={"font-bold"}>Overall Test Results / Field Inspection Report [.PDF]</h3>
              <p className={"text-slate-800"}>De uitlslagen in een .pdf bestand</p>
            </div>
            <div className={"flex items-center"}>
              <Button size={'md'} type={'secondary'} icon={faDownload} text={'Download .pdf [Overall Test Results]'}
                      onClick={() => {
                        window.open(getExamReportUrl(props.exam.id), '_blank')
                        // window.open(getExamExportUrl(props.exam.id, true), '_blank')
                      }}/>
            </div>
          </div>
        </div> : <div className={"w-full"}>
          <div className={"grid grid-cols-2 gap-4"}>
            <div className={""}>
              <h3 className={"font-bold"}>Overall Test Results / Competency Test [.PDF]</h3>
              <p className={"text-slate-800"}>Alle test uitslagen en scorekaarten van alle aanwezige <br/>contractors in
                één
                .pdf file </p>
            </div>
            <div className={"flex items-center"}>
              <Button size={'md'} type={'secondary'} icon={faDownload} text={'Download .pdf [Overall Test Results]'}
                      onClick={() => {
                        window.open(getExamReportUrl(props.exam.id), '_blank')
                        // window.open(getExamExportUrl(props.exam.id, true), '_blank')
                      }}/>
            </div>
            <div className={""}>
              <h3 className={"font-bold"}>Test Results / Competency Test per Contractor [.PDF]</h3>
              <p className={"text-slate-800"}>Alle test uitslagen en scorekaarten van alle aanwezige <br/>contractors
                apart
                in een .pdf file</p>
            </div>
            <div className={"flex flex-col items-start"}>
              {Array.from(new Set(props.exam.participants.map(p => p.contractor) ?? [])).map((contractor, i) => {
                return <div key={i} className={'border border-slate-200 rounded w-full px-3 py-2 mb-3'}>
                  <h2 className={'font-bold text-brand-900'}>{contractor}</h2>
                  <Button size={'md'} type={'secondary'} icon={faDownload}
                          text={`Download .pdf [Test Results per Contractor]`}
                          onClick={() => {
                            window.open(getExamReportUrl(props.exam.id, contractor), '_blank')
                          }}/>
                </div>
              })}
            </div>
            <div className={""}>
              <h3 className={"font-bold"}>Overall Test Results / Competency Test [.XSLX]</h3>
              <p className={"text-slate-800"}>Alle test uitslagen van alle contractors in één .xlsx file</p>
            </div>
            <div className={"flex items-center"}>
              <Button size={'md'} type={'secondary'} icon={faDownload} text={'Download .xlsx [Overall Test Results]'}
                      onClick={() => {
                        window.open(getExamExportUrl(props.exam.id, false), '_blank')
                      }}/>
            </div>
            <div className={""}>
              <h3 className={"font-bold"}>Export Overall Data Scorecard [.XSLX]</h3>
              <p className={"text-slate-800"}>Volledig export van alle ingevulde scorecards in één .xlsx file.</p>
            </div>
            <div className={"flex flex-col items-start"}>
              <Button size={'md'} type={'secondary'} icon={faDownload}
                      text={`Download .xlsx [Export Overall Data Scorecard]`}
                      onClick={() => {
                        window.open(getExamExportUrl(props.exam.id, true), '_blank')
                      }}/>
            </div>

          </div>
        </div>}
      </div>}
      {props.exam.status === 'In uitvoering' && !override &&
        <div className={'flex flex-col items-center space-y-3 py-4'}>
          <FontAwesomeIcon icon={faClock} className={'text-brand-900'}/>
          <h2 className={"font-lg font-semibold text-brand-800"}>In progress</h2>
          <p className={"text-sm text-brand-700"}>Dit examen is nog niet afgerond, pas dan kun je de resultaten
            exporteren</p>
          <button className={"text-sm underline text-red-700"} onClick={() => setOverride(true)}>Toch nu exporteren
          </button>
        </div>}
      {props.exam.status === 'Concept' && !override && <div className={'flex flex-col items-center space-y-3 py-4'}>
        <FontAwesomeIcon icon={faPencil} className={'text-brand-900'}/>
        <h2 className={"font-lg font-semibold text-brand-800"}>Concept</h2>
        <p className={"text-sm text-brand-700"}>Dit examen is nog niet afgerond, pas dan kun je de resultaten
          exporteren</p>
        <button className={"text-sm underline text-red-700"} onClick={() => setOverride(true)}>Toch nu exporteren
        </button>
      </div>}
    </Card>
  </div>

}

const ExamParticipantsOverview: FC<{ exam: EnrichedExam }> = props => {
  return <div>
    <SectionDivider/>
    {props.exam.questionSet.type === 'Fir' ? <>
      <SectionHeader>Details</SectionHeader>
      <DataTable<ExamParticipant> keyProperty={'id'} data={props.exam.participants}
                                  columns={[
                                    {header: 'Field inspection number', property: 'name'},
                                    {header: 'Job Number', property: 'contractor'},
                                    {header: 'Job Location', property: 'subContractor'},
                                    {header: 'Equipment Number', property: 'badgeNumber'},
                                  ]} placeholder={<>Nog geen details</>}/>
    </> : <>
      <SectionHeader>Deelnemers</SectionHeader>
      <DataTable<ExamParticipant> keyProperty={'id'} data={props.exam.participants}
                                  columns={[
                                    {header: 'Position', property: 'position'},
                                    {
                                      header: 'Naam',
                                      property: 'name',
                                      transform: (name, participant) => participant.noShow ?
                                        <span className={'text-red-700 font-bold'}>{name} (NO-SHOW)</span> :
                                        <span>{name}</span>
                                    },
                                    {header: 'Contractor', property: 'contractor'},
                                    {header: 'Subcontractor', property: 'subContractor'},
                                    {header: 'Badge', property: 'badgeNumber'},
                                    {header: 'Certification', property: 'certification'},
                                    {header: 'Score', property: 'score'},
                                  ]} placeholder={<>Nog geen deelnemers</>}/>
    </>}

  </div>
}


const ExamDetailsContextMenu: FC<{ exam: EnrichedExam, companies: Company[], projects: Project[], exams: Exam[] }> = props => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const {keycloak} = useKeycloak()
  const {archiveExam, destroyExam, revertExam} = useApiCall()
  const navigate = useNavigate()
  const {refreshAll} = useApi()
  const roles = useMemo(() => keycloak.tokenParsed?.realm_access?.roles ?? [], [keycloak.tokenParsed])
  const deleteExam = async () => {
    if (!props.exam.id) return
    if (!window.confirm('Weet je zeker dat je dit examen wilt archiveren?')) return
    await archiveExam(props.exam.id)
    refreshAll()
    navigate('/')
  }

  const permanentDeleteExam = async () => {
    if (!props.exam.id) return
    if (!window.confirm('Weet je zeker dat je dit examen permanent wilt verwijderen? \n Deze actie valt niet terug te draaien')) return
    await destroyExam(props.exam.id)
    refreshAll()
    navigate('/')
  }

  const revertArchivedExam = async () => {
    if (!props.exam.id) return
    if (!window.confirm('Weet je zeker dat je dit examen terug wilt zetten?')) return
    await revertExam(props.exam.id)
    refreshAll()
    navigate('/')
  }

  const editExamModal = useModal({title: "Examen bewerken", size: 'xl', body: <EditExamModal companies={props.companies} projects={props.projects} exams={props.exams} exam={props.exam} />})
  const copyExamModal = useModal({title: "Examen dupliceren", body: <CloneExamModal companies={props.companies} projects={props.projects} exams={props.exams} exam={props.exam} />})
  const moveParticipantModal = useModal({title: "Deelnemer verplaatsen", body: <MoveExamParticipantModal exams={props.exams} exam={props.exam} />})

  const options = <>
    {props.exam.archivedAt ? <>
      <ExamDetailsContextMenuOption text={'Examen terugzetten'} icon={faBackward} onClick={revertArchivedExam} />
      {roles.includes('admin') && <ExamDetailsContextMenuOption danger text={'Examen permanent Verwijderen'} icon={faCircleExclamation} onClick={permanentDeleteExam}/>}
    </> : <>
      {roles.includes('admin') && <ExamDetailsContextMenuOption text={'Examen bewerken'} icon={faPencil} onClick={() => editExamModal.open()}/>}
      {roles.includes('admin') && <ExamDetailsContextMenuOption text={'Examen dupliceren'} icon={faCopy} onClick={() => copyExamModal.open()}/>}
      {roles.includes('admin') && <ExamDetailsContextMenuOption text={'Deelnemer verplaatsen'} icon={faArrowsLeftRight} onClick={() => moveParticipantModal.open()}/>}
      <ExamDetailsContextMenuOption text={'Examen archiveren'} danger icon={faArchive} onClick={deleteExam} />
    </>}
  </>

  return <div className={'ml-3 mt-1 relative'}>
    <IconButton size={'md'} type={'primary'} icon={faEllipsisV} onClick={() => setExpanded(true)} />
    {expanded && <>
      <div className={'fixed inset-0 bg-[#0001]'} onClick={() => setExpanded(false)}></div>
      <div className={'absolute top-10 right-0 min-w-48 bg-white rounded shadow-lg border border-slate-300'}>{options}</div>
    </>}
  </div>
}
const ExamDetailsContextMenuOption: FC<{text: string, icon: IconDefinition, danger?: true, onClick: () => Promise<void>|void}> = props => {
  return <button className={`flex w-full items-center h-10 px-2 ${props.danger ? 'text-red-600 hover:bg-red-50' : 'text-brand-900 hover:bg-brand-50'}`} onClick={props.onClick}>
    <div className={'w-10 h-10 flex items-center justify-center -ml-2'}>
      <FontAwesomeIcon icon={props.icon} className={''}/>
    </div>
    <span className={'whitespace-nowrap'}>{props.text}</span>
  </button>
}
